import * as React from "react";

import { Layout } from "@components/Layout";
import { Container } from "@components/Layout/Container";
import { heroAtom, seoAtom } from "@/store";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { UnderConstruction } from "@components/UnderConstruction";
import { Helmet } from "react-helmet";
import { EmailListForm } from "@components/EmailListForm";
import { useHydrateAtoms } from "jotai/utils";

type UnderConstructionProps = {
  pageContext?: {
    heading?: string;
    description?: string;
  };
};

const Page = ({
  pageContext: { heading = "Under Construction", description = "Under Construction" }
}: UnderConstructionProps): JSX.Element => {
  useHydrateAtoms([
    [heroAtom, undefined],
    [seoAtom, { heading, description }],
  ]);
  return (
    <>
      <Helmet>
        <title> {heading} | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <Title>{heading}</Title>
          </Header>
          <div className="flex justify-center mt-32">
            <EmailListForm headerText="This section is coming soon." />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default Page;
